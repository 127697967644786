import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Button = styled.button`
  display: block;
  padding: 30px 40px;
  background: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.mainFont};
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.bold};
  border: 1px solid ${({ theme }) => theme.colors.blue};
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  box-shadow: 0 1px 30px -5px rgba(0, 0, 0, 0.2);
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.dark};
    }
  }
  ${({ submit }) =>
    submit &&
    css`
      position: relative;
      height: 60px;
      max-width: 220px;
      cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
      padding: 0;
      ${({ theme }) => theme.mq.s} {
        height: 80px;
        padding: 30px 40px;
        max-width: 300px;
      }
      &:before {
        content: '';
        position: absolute;
        top: 18px;
        left: 48%;
        width: 24px;
        height: 24px;
        transform: translateX(-48%);
        border: 3px solid ${({ theme }) => theme.white};
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        opacity: ${({ disabled }) => (disabled ? '1' : '0')};
        animation: ${spin} 1s ease infinite;
        ${({ theme }) => theme.mq.s} {
          top: 28px;
        }
      }
      ${({ disabled }) =>
        disabled &&
        css`
          &:hover {
            background: ${({ theme }) => theme.colors.blue};
            color: ${({ theme }) => theme.colors.white};
          }
        `}
    `}
`;

export default Button;
