import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import LazyImage from 'components/shared/LazyImage';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import scrollToSection from 'utils/scrollToSection';
import fbIcon from 'assets/icons/facebook.svg';
import arrowRightIcon from 'assets/icons/arrow-right.svg';
import scrollIcon from 'assets/icons/mouse.svg';
import phoneIcon from 'assets/icons/phone.svg';
import mailIcon from 'assets/icons/mail.svg';

const Wrapper = styled.header`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 450px;
  background: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.md} {
    min-height: 700px;
  }
  ${({ theme }) => theme.mq.xl} {
    min-height: 900px;
  }
  ${({ fixed }) =>
    fixed &&
    css`
      overflow: hidden;
      ${({ theme }) => theme.mq.md} {
        height: calc(100vh + 100px);
      }
    `}
`;

const Title = styled.h1`
  position: relative;
  font-size: 3.3rem;
  line-height: 4rem;
  font-weight: ${({ theme }) => theme.light};
  display: flex;
  align-items: center;
  color: #282828;
  white-space: nowrap;
  &:after {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 55%;
    height: 4px;
    background: #282828;
  }
  ${({ theme }) => theme.mq.xs} {
    font-size: 4rem;
    line-height: 5rem;
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 5rem;
    line-height: 6rem;
  }
  ${({ theme }) => theme.mq.md} {
    font-size: 5rem;
    line-height: 6rem;
    background: linear-gradient(to right, #282828 305px, #fff 0);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: ${({ theme }) => theme.fontSize.xxl};
    line-height: 7.5rem;
    background: linear-gradient(to right, #282828 365px, #fff 0);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: ${({ theme }) => theme.fontSize.xxxl};
    line-height: 9rem;
    background: linear-gradient(to right, #282828 383px, #fff 0);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  ${({ extended }) =>
    extended &&
    css`
      ${({ theme }) => theme.mq.md} {
        color: ${({ theme }) => theme.colors.white} !important;
        &:after {
          background: ${({ theme }) => theme.colors.white};
        }
      }
    `}
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  pointer-events: none;
  user-select: none;
  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
`;

const ControlPanel = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  right: 50px;
  align-items: center;
  flex-direction: column;
  transform: translateY(-50%);
  z-index: 5;
  ${({ theme }) => theme.mq.md} {
    display: flex;
  }
`;

const ControlButton = styled.div`
  width: 2px;
  height: 30px;
  margin: 5px 0;
  cursor: pointer;
  transition: 0.6s;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.blue : theme.colors.gray300};
`;

const SlideWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${({ theme }) => theme.mq.md} {
    right: 100px;
    left: auto;
    top: auto;
    ${({ extended }) =>
      extended &&
      css`
        right: 100px;
        width: calc(100% - 285px);
      `}
  }
`;

const SlideInnerWrapper = styled.div`
  position: relative;
  width: 100vw;
  min-height: 600px;
  height: 100vh;
  ${({ theme }) => theme.mq.md} {
    width: 45vw;
    height: 100vh;
  }
  ${({ theme }) => theme.mq.lg} {
    min-height: 700px;
  }
  ${({ theme }) => theme.mq.xl} {
    width: 55vw;
    min-height: 900px;
    height: 100vh;
  }
  ${({ extended }) =>
    extended &&
    css`
      width: 100% !important;
    `}
  &:before {
    content: '';
    position: absolute;
    top: 200px;
    left: 60px;
    width: 500px;
    height: 800px;
    background: ${({ theme }) => theme.colors.gray100};
    ${({ theme }) => theme.mq.md} {
      left: -200px;
    }
    ${({ theme }) => theme.mq.lg} {
      left: -300px;
      height: 950px;
      width: 600px;
    }
    ${({ theme }) => theme.mq.xl} {
      height: 1200px;
    }
    ${({ extended }) =>
      extended &&
      css`
        ${({ theme }) => theme.mq.md} {
          left: 100px;
        }
        ${({ theme }) => theme.mq.lg} {
          left: 230px;
        }
      `}
  }
`;

const Slide = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 45vw;
  min-height: 600px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  overflow: hidden;
  background-color: #000;
  ${({ theme }) => theme.mq.md} {
    display: block;
    height: 100vh;
  }
  ${({ theme }) => theme.mq.lg} {
    min-height: 700px;
  }
  ${({ theme }) => theme.mq.xl} {
    width: 55vw;
    min-height: 900px;
    height: 100vh;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
    `}
  ${({ extended }) =>
    extended &&
    css`
      width: 100% !important;
    `}
`;

const ContentWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
  & > ${Button} {
    margin-top: 60px;
    margin-right: 200px;
    padding: 25px 35px;
  }
  @media (min-width: 350px) {
    left: 30px;
  }
  ${({ theme }) => theme.mq.s} {
    left: 80px;
    top: 65%;
    transform: translateY(-65%);
    & > ${Button} {
      margin-top: 80px;
      padding: 35px 40px;
    }
  }
  ${({ theme }) => theme.mq.md} {
    left: -305px;
  }
  ${({ theme }) => theme.mq.lg} {
    left: -365px;
  }
  ${({ theme }) => theme.mq.xl} {
    left: -383px;
  }
  ${({ extended }) =>
    extended &&
    css`
      ${({ theme }) => theme.mq.md} {
        left: 20px !important;
      }
      ${({ theme }) => theme.mq.lg} {
        left: 100px !important;
      }
    `}
  ${({ btnLeft }) =>
    btnLeft &&
    css`
      & > ${Button} {
        margin: 70px auto 0 0 !important;
      }
    `}
  ${({ noBtn }) =>
    noBtn &&
    css`
      & > ${Title} {
        background: transparent;
        color: #282828;
      }
      ${({ theme }) => theme.mq.md} {
        top: 50% !important;
        transform: translateY(-50%) !important;
      }
      ${({ theme }) => theme.mq.lg} {
        left: -380px !important;
      }
      ${({ theme }) => theme.mq.xl} {
        left: -440px !important;
      }
    `}
`;

const ScrollWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 0;
  cursor: pointer;
  ${({ theme }) => theme.mq.md} {
    display: block;
    left: -45px;
    right: auto;
    bottom: 20px;
  }
`;

const RotatedText = styled.span`
  display: flex;
  align-items: center;
  transform: rotate(-90deg);
  margin-bottom: 100px;
  text-transform: uppercase;
  font-size: 1.8rem;
  &:before {
    content: '';
    height: 2px;
    width: 75px;
    margin-right: 15px;
    background: ${({ theme }) => theme.colors.dark};
    ${({ theme }) => theme.mq.lg} {
      display: none;
    }
  }
`;

const SocialMedia = styled.a`
  position: absolute;
  top: 80px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background: ${({ theme }) => theme.colors.white};
  border-right: none;
  border-top: none;
  width: 90px;
  height: 90px;
  ${({ theme }) => theme.mq.md} {
    border: none;
    width: auto;
    height: auto;
    top: 150px;
    right: 35px;
  }
`;

const PhoneBox = styled(SocialMedia)`
  right: 90px;
  ${({ theme }) => theme.mq.md} {
    display: none;
  }
`;

const EmailBox = styled(PhoneBox)`
  right: 180px;
`;

const ArrowControlls = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: none;
  align-items: center;
  ${({ theme }) => theme.mq.md} {
    display: flex;
  }
`;

const Arrow = styled.button`
  background: transparent;
  width: 24px;
  height: 48px;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
  filter: invert(1);
  @media (hover: hover) {
    &:hover {
      filter: invert(0.5);
    }
  }
`;

const ArrowLeft = styled(Arrow)`
  transform: rotate(180deg);
`;

const Spacer = styled.figure`
  width: 2px;
  height: 55px;
  background: ${({ theme }) => theme.colors.white};
  margin: 0 30px;
`;

const IconWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;

const Header = ({ slides, heading, noButton, notFound }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const timeoutRef = useRef(null);

  const isSlider = slides.length > 1;

  const slide = useCallback(() => {
    const nextSlide = (activeSlide + 1) % slides.length;
    setActiveSlide(nextSlide);
  }, [activeSlide]);

  const changeToSlide = slideIndex => {
    if (!isAnimating) setActiveSlide(slideIndex);
  };

  const goPrev = () => {
    const nextSlide =
      activeSlide - 1 === -1 ? slides.length - 1 : activeSlide - 1;

    changeToSlide(nextSlide);
  };

  const goNext = () => {
    const nextSlide = activeSlide + 1 === slides.length ? 0 : activeSlide + 1;

    changeToSlide(nextSlide);
  };

  useEffect(() => {
    const setSlideTimeout = () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(slide, 3000);

      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 1000);
    };

    slides.length > 1 && setSlideTimeout();

    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(setSlideTimeout);
    };
  }, [activeSlide, slide]);

  return (
    <Wrapper fixed={notFound}>
      {slides.length > 1 && (
        <ControlPanel>
          {slides.map((_, index) => (
            <ControlButton
              key={index}
              onClick={() => changeToSlide(index)}
              isActive={index === activeSlide}
              aria-label={`${index + 1} slide`}
              role="button"
            />
          ))}
        </ControlPanel>
      )}
      <SlideWrapper extended={isSlider}>
        <SlideInnerWrapper extended={isSlider}>
          {slides.map(({ image, imageAlt }, index) => (
            <Slide
              key={index}
              extended={isSlider}
              isActive={index === activeSlide}
            >
              <ImageWrapper>
                <LazyImage src={image} alt={imageAlt} cover />
              </ImageWrapper>
            </Slide>
          ))}
          <ContentWrapper
            extended={isSlider}
            btnLeft={notFound}
            noBtn={noButton}
          >
            <Title
              extended={isSlider}
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            {!noButton && (
              <>
                {notFound ? (
                  <Button as={Link} to="/">
                    Strona główna
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      scrollToSection(!isSlider ? '#content' : '#about')
                    }
                  >
                    Więcej
                  </Button>
                )}
              </>
            )}
          </ContentWrapper>
          {isSlider && (
            <ArrowControlls>
              <ArrowLeft onClick={goPrev} aria-label="slide left">
                <Icon src={arrowRightIcon} alt="slide right" full />
              </ArrowLeft>
              <Spacer />
              <Arrow onClick={goNext} aria-label="slide right">
                <Icon src={arrowRightIcon} alt="slide left" full />
              </Arrow>
            </ArrowControlls>
          )}
        </SlideInnerWrapper>
      </SlideWrapper>
      <SocialMedia
        href="https://www.facebook.com/Polisa-Centrum-Ubezpiecze%C5%84-2314868155505917"
        target="_blank"
      >
        <Icon src={fbIcon} alt="facebook" size={26} />
      </SocialMedia>
      <PhoneBox href="tel:+48 888 219 451">
        <Icon src={phoneIcon} alt="phone" size={26} />
      </PhoneBox>
      <EmailBox href="mailto: polisa@koszalinubezpieczenia.pl">
        <Icon src={mailIcon} alt="email" size={26} />
      </EmailBox>
      {!notFound && (
        <ScrollWrapper
          onClick={() => scrollToSection(!isSlider ? '#content' : '#about')}
        >
          <RotatedText>Dowiedz się więcej</RotatedText>
          <IconWrapper>
            <Icon src={scrollIcon} alt="click to scroll" full />
          </IconWrapper>
        </ScrollWrapper>
      )}
    </Wrapper>
  );
};

Header.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  heading: PropTypes.string.isRequired,
  noButton: PropTypes.bool,
  notFound: PropTypes.bool,
};

Header.defaultProps = {
  noButton: false,
  notFound: false,
};

export default Header;
